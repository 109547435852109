<template>
    <div class="home">
        <!-- <navbar/>
         -->
      
        <router-view />
        <div style="height: 90px;border-radius: 25px;padding: 10px 0;background-color: white;width: 90%;position: fixed; bottom: 10px;left:5%">
    <div style="width: 100%;z-index: 1;height: 50px;border-radius: 25px;" class="btn-group" role="group" aria-label="Basic example">
        <a style="text-decoration: none;" href="/" class="svgbox"  >
          
          <div style="height: 35px;width: 35px;border-radius: 50%;background-color: #8479b2;margin:auto " :style="[roout == '/' ? {'height': '45px','width': '45px','border-radius': '50%','margin':'auto', 'padding': '3px'} : {'height': '35px','width': '35px','border-radius': '50%','margin':'auto'}]">
            <svg fill="#ffffff" style="" :height="[roout == '/' ? 24 : 21]" :width="[roout == '/' ? 24 : 21]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 324.143 324.143" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="88.071" cy="164.571" r="19"/>
			<circle cx="162.071" cy="164.571" r="19"/>
			<path d="M162.071,0C73.162,0,0.83,72.332,0.83,161.241c0,37.076,12.788,73.004,36.1,101.677
				c-6.65,16.756-17.788,31.245-32.401,42.089c-2.237,1.66-3.37,4.424-2.94,7.177c0.429,2.754,2.349,5.042,4.985,5.942
				c11.683,3.992,23.856,6.017,36.182,6.017c19.572,0,38.698-5.093,55.569-14.763c20.158,8.696,41.584,13.104,63.747,13.104
				c88.909,0,161.241-72.333,161.241-161.242S250.98,0,162.071,0z M162.071,307.483c-21.32,0-41.881-4.492-61.11-13.351
				c-2.292-1.057-4.959-0.891-7.102,0.443c-15.313,9.529-32.985,14.566-51.104,14.566c-6.053,0-12.065-0.564-17.981-1.684
				c12.521-12.12,22.014-26.95,27.788-43.547c0.878-2.525,0.346-5.328-1.398-7.354C28.378,230.07,15.83,196.22,15.83,161.241
				C15.83,80.604,81.434,15,162.071,15s146.241,65.604,146.241,146.241C308.313,241.88,242.709,307.483,162.071,307.483z"/>
			<circle cx="236.071" cy="164.571" r="19"/>
		</g>
	</g>
</g>
</svg>
<br>
          <a style="position: relative; text-decoration: none; color: #333; top: 12px; font-size: 8px; font-weight: bold; width: 100%;display: block;; width: 130%; margin-left: -15%;">AI Writer</a>
          </div>
        </a>
        <a style="text-decoration: none;" href="/news" class="svgbox"  >
          
          <div style="height: 35px;width: 35px;border-radius: 50%;background-color: #97caab;margin:auto " :style="[roout == '/news' ? {'height': '45px','width': '45px','border-radius': '50%','margin':'auto', 'padding': '3px'} : {'height': '35px','width': '35px','border-radius': '50%','margin':'auto'}]">
            <svg fill="#ffffff" style="" :height="[roout == '/news' ? 24 : 21]" :width="[roout == '/news' ? 24 : 21]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 324.143 324.143" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="88.071" cy="164.571" r="19"/>
			<circle cx="162.071" cy="164.571" r="19"/>
			<path d="M162.071,0C73.162,0,0.83,72.332,0.83,161.241c0,37.076,12.788,73.004,36.1,101.677
				c-6.65,16.756-17.788,31.245-32.401,42.089c-2.237,1.66-3.37,4.424-2.94,7.177c0.429,2.754,2.349,5.042,4.985,5.942
				c11.683,3.992,23.856,6.017,36.182,6.017c19.572,0,38.698-5.093,55.569-14.763c20.158,8.696,41.584,13.104,63.747,13.104
				c88.909,0,161.241-72.333,161.241-161.242S250.98,0,162.071,0z M162.071,307.483c-21.32,0-41.881-4.492-61.11-13.351
				c-2.292-1.057-4.959-0.891-7.102,0.443c-15.313,9.529-32.985,14.566-51.104,14.566c-6.053,0-12.065-0.564-17.981-1.684
				c12.521-12.12,22.014-26.95,27.788-43.547c0.878-2.525,0.346-5.328-1.398-7.354C28.378,230.07,15.83,196.22,15.83,161.241
				C15.83,80.604,81.434,15,162.071,15s146.241,65.604,146.241,146.241C308.313,241.88,242.709,307.483,162.071,307.483z"/>
			<circle cx="236.071" cy="164.571" r="19"/>
		</g>
	</g>
</g>
</svg>
<br>
          <a style="position: relative; text-decoration: none; color: #333; top: 12px; font-size: 8px; font-weight: bold; width: 100%;display: block;; width: 130%; margin-left: -15%;"> AI News</a>
          </div>
        </a><a class="svgbox"  style="text-decoration: none;">
          
          <div style=" " :style="[roout == '/write' ? {'height': '45px','width': '45px','border-radius': '50%','background-color': 'black','margin':'auto', 'padding': '3px'} : {'height': '35px','width': '35px','border-radius': '50%','background-color': 'black','margin':'auto'}]">
            <svg fill="#ffffff" :height="[roout == '/write' ? 24 : 21]" :width="[roout == '/write' ? 24 : 21]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 324.143 324.143" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="88.071" cy="164.571" r="19"/>
			<circle cx="162.071" cy="164.571" r="19"/>
			<path d="M162.071,0C73.162,0,0.83,72.332,0.83,161.241c0,37.076,12.788,73.004,36.1,101.677
				c-6.65,16.756-17.788,31.245-32.401,42.089c-2.237,1.66-3.37,4.424-2.94,7.177c0.429,2.754,2.349,5.042,4.985,5.942
				c11.683,3.992,23.856,6.017,36.182,6.017c19.572,0,38.698-5.093,55.569-14.763c20.158,8.696,41.584,13.104,63.747,13.104
				c88.909,0,161.241-72.333,161.241-161.242S250.98,0,162.071,0z M162.071,307.483c-21.32,0-41.881-4.492-61.11-13.351
				c-2.292-1.057-4.959-0.891-7.102,0.443c-15.313,9.529-32.985,14.566-51.104,14.566c-6.053,0-12.065-0.564-17.981-1.684
				c12.521-12.12,22.014-26.95,27.788-43.547c0.878-2.525,0.346-5.328-1.398-7.354C28.378,230.07,15.83,196.22,15.83,161.241
				C15.83,80.604,81.434,15,162.071,15s146.241,65.604,146.241,146.241C308.313,241.88,242.709,307.483,162.071,307.483z"/>
			<circle cx="236.071" cy="164.571" r="19"/>
		</g>
	</g>
</g>
</svg>
            <br>
          <a style="position: relative; text-decoration: none; color: #333; top:12px;font-size: 8px; font-weight: bold; width: 100%;display: block;"> Write</a>
          </div>
        </a> <a class="svgbox"  style="text-decoration: none;">
          
          <div style="height: 35px;width: 35px;border-radius: 50%;background-color: #818dc5;margin:auto " :style="[roout == '/analysis' ? {'height': '45px','width': '45px','border-radius': '50%','margin':'auto', 'padding': '3px'} : {'height': '35px','width': '35px','border-radius': '50%','margin':'auto'}]">
            <svg fill="#ffffff" style="" :height="[roout == '/analysis' ? 24 : 21]" :width="[roout == '/analysis' ? 24 : 21]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 324.143 324.143" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="88.071" cy="164.571" r="19"/>
			<circle cx="162.071" cy="164.571" r="19"/>
			<path d="M162.071,0C73.162,0,0.83,72.332,0.83,161.241c0,37.076,12.788,73.004,36.1,101.677
				c-6.65,16.756-17.788,31.245-32.401,42.089c-2.237,1.66-3.37,4.424-2.94,7.177c0.429,2.754,2.349,5.042,4.985,5.942
				c11.683,3.992,23.856,6.017,36.182,6.017c19.572,0,38.698-5.093,55.569-14.763c20.158,8.696,41.584,13.104,63.747,13.104
				c88.909,0,161.241-72.333,161.241-161.242S250.98,0,162.071,0z M162.071,307.483c-21.32,0-41.881-4.492-61.11-13.351
				c-2.292-1.057-4.959-0.891-7.102,0.443c-15.313,9.529-32.985,14.566-51.104,14.566c-6.053,0-12.065-0.564-17.981-1.684
				c12.521-12.12,22.014-26.95,27.788-43.547c0.878-2.525,0.346-5.328-1.398-7.354C28.378,230.07,15.83,196.22,15.83,161.241
				C15.83,80.604,81.434,15,162.071,15s146.241,65.604,146.241,146.241C308.313,241.88,242.709,307.483,162.071,307.483z"/>
			<circle cx="236.071" cy="164.571" r="19"/>
		</g>
	</g>
</g>
</svg>
<br>
          <a style="position: relative; text-decoration: none; color: #333; top: 12px; font-size: 8px; font-weight: bold; width: 100%;display: block;; width: 130%; margin-left: -15%;">AI Analysis</a>
          </div>
        </a> <a style="text-decoration: none;" class="svgbox"  >
          
          <div style="height: 35px;width: 35px;border-radius: 50%;background-color: #ed8794;margin:auto " :style="[roout == '/profile' ? {'height': '45px','width': '45px','border-radius': '50%','margin':'auto', 'padding': '3px'} : {'height': '35px','width': '35px','border-radius': '50%','margin':'auto'}]">
            <svg fill="#ffffff" style="" :height="[roout == '/profile' ? 24 : 21]" :width="[roout == '/profile' ? 24 : 21]" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 324.143 324.143" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="88.071" cy="164.571" r="19"/>
			<circle cx="162.071" cy="164.571" r="19"/>
			<path d="M162.071,0C73.162,0,0.83,72.332,0.83,161.241c0,37.076,12.788,73.004,36.1,101.677
				c-6.65,16.756-17.788,31.245-32.401,42.089c-2.237,1.66-3.37,4.424-2.94,7.177c0.429,2.754,2.349,5.042,4.985,5.942
				c11.683,3.992,23.856,6.017,36.182,6.017c19.572,0,38.698-5.093,55.569-14.763c20.158,8.696,41.584,13.104,63.747,13.104
				c88.909,0,161.241-72.333,161.241-161.242S250.98,0,162.071,0z M162.071,307.483c-21.32,0-41.881-4.492-61.11-13.351
				c-2.292-1.057-4.959-0.891-7.102,0.443c-15.313,9.529-32.985,14.566-51.104,14.566c-6.053,0-12.065-0.564-17.981-1.684
				c12.521-12.12,22.014-26.95,27.788-43.547c0.878-2.525,0.346-5.328-1.398-7.354C28.378,230.07,15.83,196.22,15.83,161.241
				C15.83,80.604,81.434,15,162.071,15s146.241,65.604,146.241,146.241C308.313,241.88,242.709,307.483,162.071,307.483z"/>
			<circle cx="236.071" cy="164.571" r="19"/>
		</g>
	</g>
</g>
</svg>
<br>
          <a style="position: relative; text-decoration: none; color: #333; top: 12px; font-size: 8px; font-weight: bold; width: 100%;display: block;; width: 130%; margin-left: -15%;"> Writer</a>
          </div>
        </a>
    </div>
  </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import navbar from '@/components/Layouts/navbar.vue'
import { useRoute } from "vue-router";
  export default {
    name: 'HomeView',
    components: {
      navbar
    },
	data(){
		return{
			roout : null
		}
	},
	mounted(){
		var route = useRoute()
		this.roout = route.path
	}
    
  }
  </script>
  <style>
.svgbox{
  height: 50px;width: 25%;; border-radius: 25px;border: none; box-shadow: none; background-color: transparent;
}
.svgbox svg{
  margin: 7px
}
</style>